<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Configuração Análise de Crédito</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="3"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="configAnaliseCreditoRequest.bloqueia"
                        label="Sugere Análise de Crédito?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="6"
                    lg="6"
                    md="6"
                    sm="6"
                >
                    <label>Valor Inicial</label>
                    <v-text-field
                        v-model="configAnaliseCreditoRequest.valorInicial"
                        v-formatMoney="moneyFormat"
                        :rules=[validations.required]
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="6"
                    lg="6"
                    md="6"
                    sm="6"
                >
                    <label>Valor Final</label>
                    <v-text-field
                        v-model="configAnaliseCreditoRequest.valorFinal"
                        v-formatMoney="moneyFormat"
                        :rules=[validations.required]
                        outlined
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <label>Descrição</label>
                    <v-text-field
                        v-model="configAnaliseCreditoRequest.descricao" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <label>Tempo para Análise</label>
                    <v-text-field
                        v-model="configAnaliseCreditoRequest.descricaoAnaliseDias" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            
            <br />

            <ActionButtons
                :request="configAnaliseCreditoRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons
        },

        data: () => ({

            validForm: true,

            configAnaliseCreditoRequest: {
                id: 0,
                valorInicial: "R$ 0,00",
                valorFinal: "R$ 0,00",
                descricao: "",
                descricaoAnaliseDias: "",
            },

            loading: false,
            
            validations: {
                required: required,
            },

            moneyFormat: {
                prefix: `R$ `,
                suffix: '',
                thousands: '.',
                decimal: ',',
                precision: 2,
            }
        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("analiseCreditoModule/GetById", this.id);

                    if (response.success) {
                        this.configAnaliseCreditoRequest = response.result;
                        this.configAnaliseCreditoRequest.valorInicial = this.configAnaliseCreditoRequest.valorInicialFormatted;
                        this.configAnaliseCreditoRequest.valorFinal = this.configAnaliseCreditoRequest.valorFinalFormatted;
                    }
                }
            },

            async cancel() {
                this.$router.push({ path: "/analiseCredito/configAnaliseCreditoList" });
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Aviso!", "Há inconsistências no formulário. Por favor revise!");
                    return false;
                }
                else {
                    this.loading = true;
                    this.showLoading();

                    this.configAnaliseCreditoRequest.id = this.id;

                    const result = await this.$store.dispatch("analiseCreditoModule/CreateUpdate", this.configAnaliseCreditoRequest);

                    if (result.success === true) {
                        this.showToast("success", "Sucesso!", result.message);
                        this.cancel();
                    }
                    else {
                        this.showToast("error", "Aviso!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            }
        },

        created() {
            this.showLoading();

            this.getRegister();

            this.hideLoading();
        }
    })
</script>